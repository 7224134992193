import(/* webpackMode: "eager", webpackExports: ["DocumentTitleManager"] */ "/app/apps/dashboard/app/_components/DocumentTitleManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppConfigProvider"] */ "/app/apps/dashboard/app/_data-providers/AppConfigProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientifiedHotkeysProvider"] */ "/app/apps/dashboard/app/_data-providers/ClientifiedHotkeysProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogerProvider","Dialoger"] */ "/app/apps/dashboard/app/_data-providers/Dialoger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/dashboard/app/_data-providers/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/apps/dashboard/app/_data-providers/session-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/app/apps/dashboard/app/_data-providers/swr-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/dashboard/app/feedback-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/dashboard/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/apps/dashboard/lib/router-events/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TopLoader"] */ "/app/apps/dashboard/lib/Toploader.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/packages/hoose/dist-css/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Cairo\",\"arguments\":[{\"subsets\":[\"arabic\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--cairo-font\",\"adjustFontFallback\":false,\"display\":\"fallback\"}],\"variableName\":\"cairoFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--opensans-font\",\"fallback\":[\"var(--cairo-font)\"],\"adjustFontFallback\":false,\"display\":\"swap\"}],\"variableName\":\"interFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/packages/hoose/dist-separated/headless/nextThemes.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/packages/hoose/dist-separated/ui/sonner.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/packages/hoose/dist-separated/ui/tooltip.mjs");
